<!-- 组件说明 -->
<template>
    <div class="correct_list">
        <div class="opt_path mb20">
            <span @click="$router.push({ path: `/homework` })">插花作业</span>
            <span>批阅作业</span>
        </div>
        <div style="font-size: 24px; line-height: 40px">
            批阅作业：{{ statisticalObj.schoolworkName }}
        </div>
        <div
            style="
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 20px;
                color: #999;
            "
        >
            应交作业：{{ statisticalObj.totalCount }}份， 实缴作业：{{
                statisticalObj.submitCount
            }}份， 超时：份
        </div>

        <div class="ls_flex_between ls_flex_wrap mb20">
            <div class="ls_flexalign_center search_part">
                <el-select
                    v-model="params.status"
                    placeholder="全部状态"
                    size="medium"
                    @change="(params.pageIndex = 1), getList()"
                >
                    <el-option
                        v-for="dict in statusList"
                        :key="dict.value"
                        :label="dict.name"
                        :value="dict.value"
                    />
                </el-select>

                <el-select
                    v-model="params.approvalStatus"
                    placeholder="全部状态"
                    size="medium"
                    @change="(params.pageIndex = 1), getList()"
                >
                    <el-option
                        v-for="dict in appList"
                        :key="dict.value"
                        :label="dict.name"
                        :value="dict.value"
                    />
                </el-select>

                <el-select
                    v-model="params.groupId"
                    placeholder="全部标签"
                    size="medium"
                    @change="(params.pageIndex = 1), getList()"
                >
                    <el-option
                        v-for="dict in courseList"
                        :key="dict.id"
                        :label="dict.name"
                        :value="dict.id"
                    />
                </el-select>

                <el-input
                    v-model.trim="params.studentUserName"
                    size="medium"
                    placeholder="请输入学生姓名"
                ></el-input>

                <el-button
                    size="medium"
                    type="primary"
                    @click="(params.pageIndex = 1), getList()"
                >
                    查询
                </el-button>
            </div>

            <div v-if="statisticalObj.status != 2">
                <el-button
                    size="medium"
                    type="primary"
                    @click="overReviewTask()"
                >
                    已全部完成批阅
                </el-button>
                <el-button
                    size="medium"
                    type="primary"
                    @click="sendFlowerTask()"
                >
                    提醒未交作业学生
                </el-button>
            </div>
        </div>

        <div class="data_list">
            <el-table
                ref="multipleTable"
                :data="workList"
                highlight-current-row
                :header-cell-style="{ background: '#FAFAFA' }"
                style="width: 100%"
                :row-key="getRowKeys"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    :key="1"
                    v-model="seleChecked"
                    type="selection"
                    width="55"
                    align="center"
                    :reserve-selection="true"
                    :selectable="selected"
                />

                <el-table-column label="姓名">
                    <div class="ls_flex" slot-scope="scope">
                        <img
                            :src="scope.row.studentUserAvatar | imgFormat"
                            style="
                                height: 40px;
                                width: 40px;
                                margin-right: 10px;
                            "
                        />
                        <span>{{ scope.row.studentUserName }}</span>
                    </div>
                </el-table-column>
                <el-table-column label="分组">
                    <template slot-scope="scope">
                        <span>{{ scope.row.groupNames.join(",") }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="作业提交时间">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.submitTime || "-" }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="作业用时">
                    <template slot-scope="scope">
                        <span>
                            {{
                                scope.row.answerTime
                                    ? scope.row.answerTime + "分钟"
                                    : "-"
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <span
                            :class="scope.row.status == 3 ? 'main_color' : ''"
                        >
                            {{
                                scope.row.status == 1
                                    ? "未提交"
                                    : scope.row.status == 2
                                    ? "待批阅"
                                    : "已批阅"
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200px">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <span
                                v-if="scope.row.status == 3"
                                @click="toCorrect(scope.row, 1)"
                            >
                                查看作业
                            </span>
                            <span
                                v-if="scope.row.status == 2"
                                @click="toCorrect(scope.row)"
                            >
                                图片批阅
                            </span>
                            <span
                                v-if="scope.row.status == 2"
                                @click="threedCorrect(scope.row)"
                            >
                                3d批阅
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page_part ls_flexalign_center">
                <el-button
                    v-if="statisticalObj.status != 2"
                    class="pl_btn"
                    size="medium"
                    type="success"
                    @click="toCorrect('', 2)"
                >
                    批量批阅
                </el-button>
                <div class="ls_flex_1"></div>
                <el-pagination
                    v-show="total > 0"
                    :current-page="params.pageIndex"
                    :page-sizes="[10, 15, 20, 25]"
                    :page-size="params.pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
        <workDialog
            ref="workDialog"
            @getList="
                setSelect();
                getList();
            "
        />
    </div>
</template>

<script>
import workDialog from "./components/WorkDialog.vue";

export default {
    //import引入的组件需要注入到对象中才能使用
    components: { workDialog },
    data() {
        return {
            courseList: [],
            classList: [],
            statisticalObj: {},

            params: {
                approvalStatus: "",
                studentUserName: "",
                groupId: "",
                status: "",
                id: this.$route.query.id,
                pageIndex: 1,
                pageSize: 10,
            },
            statusList: [
                { value: "", name: "全部状态" },
                { value: 1, name: "未提交" },
                { value: 2, name: "已提交" },
                { value: 3, name: "超时" },
            ],
            appList: [
                { value: "", name: "全部状态" },
                { value: 1, name: "未通过" },
                { value: 2, name: "通过" },
            ],
            total: null,
            workList: [],
            dialogVisible: false,
            multipleSelection: [],
            seleChecked: false,
            ids: [],
        };
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    async created() {
        this.getList();
        this.getClass();
        this.getStatistical();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.$bus.$on("refreshGetList", () => {
            this.getList();
        });
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
    //监控data中的数据变化
    watch: {},
    //监听属性 类似于data概念
    computed: {},
    methods: {
        overReviewTask() {
            this.$confirm("全部已批阅后，作业不允许撤回。", "完成批阅", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.$ajax
                    .get("/schoolwork/finishCorrect", {
                        params: { id: this.params.id },
                    })
                    .then(res => {
                        if (res.data) {
                            this.$message.success("已完成所有批阅");
                        } else {
                            this.$message.warning(
                                "亲~ 还有已提交未批改的作业，请完成批改后再进行全部完成批阅操作。"
                            );
                        }
                    });
            });
        },
        sendFlowerTask() {
            this.$confirm(
                "学生可在公众号上收到您发出的作业提醒",
                "发送作业提醒",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }
            ).then(() => {
                this.$ajax
                    .get("/schoolwork/remindStudent", {
                        params: { id: this.params.id },
                    })
                    .then(res => {
                        this.$message.success("已发送作业提醒");
                    });
            });
        },
        getStatistical() {
            this.$ajax
                .get("/schoolwork/statistical", {
                    params: { id: this.params.id },
                })
                .then(res => {
                    this.statisticalObj = res.data;
                });
        },
        getClass() {
            this.$ajax
                .post("/user/group/getList", {})
                .then(response => {
                    this.courseList = JSON.parse(JSON.stringify(response.data));
                    this.classList = JSON.parse(JSON.stringify(response.data));
                    this.courseList.unshift({ name: "全部标签", id: "" });
                })
                .catch(err => {});
        },
        getList() {
            this.$ajax
                .post("/schoolwork/queryRecordList", this.params)
                .then(response => {
                    this.workList = response.list;
                    this.total = response.total;
                    this.workList.forEach(item => {
                        const typeIndex = this.multipleSelection.findIndex(
                            v => {
                                if (v == item.id) {
                                    return v;
                                }
                                return v;
                            }
                        );
                        if (typeIndex == -1) {
                            item["checked"] = false;
                        } else {
                            item["checked"] = true;
                        }
                    });

                    this.getSelect();
                })
                .catch(err => {});
        },
        handleSizeChange(val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.params.pageIndex = val;
            this.getList();
        },
        threedCorrect(row) {
            window.open(
                this.$editAddr +
                    "?type=schoolwork&mode=correct&id=" +
                    row.id +
                    "&productId=" +
                    row.productId +
                    `&authToken=${localStorage.getItem("authToken")}`
            );
        },
        toCorrect(row, type) {
            this.$refs.workDialog.type = type;
            this.$refs.workDialog.title = "批阅作业";

            this.$refs.workDialog.imgList = [];
            this.$refs.workDialog.imgIndex = 0;
            this.$refs.workDialog.statusList = [];
            this.$refs.workDialog.queryParams = {
                ids: [],
                status: "",
                schoolWorkPics: [],
            };

            if (type == 2) {
                if (this.ids.length === 0) {
                    this.$message.warning("请先选择学生");
                    return;
                }
                this.$refs.workDialog.title = "批量批阅作业";
                this.$refs.workDialog.imgList = [];
                this.$refs.workDialog.statusList = [];
                this.$refs.workDialog.queryParams = {
                    ids: this.ids,
                    status: "",
                    schoolworkRemark: "",
                };
                this.$refs.workDialog.dialogVisible = true;
                return;
            }

            if (type === 1) {
                this.$refs.workDialog.getResult(row.id);
            } else if (!type) {
                this.$refs.workDialog.getResult(row.id);
                this.$refs.workDialog.queryParams.ids = [row.id];
                this.$refs.workDialog.dialogVisible = true;
            } else {
                this.$refs.workDialog.queryParams.ids = [row.id];
                this.$refs.workDialog.dialogVisible = true;
            }
        },
        toWork() {
            this.$router.push({
                path: "/homeWork",
                query: { type: "0" },
            });
        },
        getRowKeys(row) {
            return row.id;
        },
        handleSelectionChange(val) {
            const selected = val;
            const arr = [];
            selected.forEach(item => {
                if (item.id) {
                    arr.push(item.id);
                }
            });
            this.ids = arr;
            this.multipleSelection = arr;
            if (this.multipleSelection.length == 0) {
                this.seleChecked = false;
                return;
            }
            this.getSelect();
        },
        setSelect() {
            this.ids = [];
            this.multipleSelection = [];
            this.seleChecked = false;
            this.$refs.multipleTable.clearSelection();
        },
        getSelect() {
            const that = this;
            // 若有一项没有选中则将checkbox状态改为未选中
            that.workList.forEach(item => {
                const typeIndex = that.multipleSelection.findIndex(v => {
                    if (v.studentId == item.id) {
                        return v;
                    }
                    return v;
                });
                if (typeIndex == -1) {
                    item["checked"] = false;
                } else {
                    item["checked"] = true;
                }
            });
            for (let i = 0; i < that.workList.length; i++) {
                if (that.workList[i].checked == false) {
                    that.seleChecked = false;
                    break;
                } else {
                    that.seleChecked = true;
                }
            }
        },
        selected(row, index) {
            if (row.status == 2) {
                return true; //不可勾选
            } else {
                return false; //可勾选
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.img {
    height: 100px;
}

.search_part {
    > * {
        + * {
            margin-left: 7px;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
            width: 160px;
        }

        &:nth-child(4) {
            width: 200px;
        }
    }
}

.title {
    font-size: 40px;
    color: rgb(141, 141, 141);
    margin-bottom: 30px;
}

.correct_list {
    padding: 20px 30px 0 0;

    & .el-tabs__nav-wrap {
        background-color: #ffffff;
        // padding-bottom: 9px;

        .el-tabs__nav-scroll {
            padding: 0 40px;

            .el-tabs__nav {
                margin-left: -23px;
            }

            .el-tabs__active-bar {
                width: 24px !important;
                margin-left: 20px;
                height: 4px;
                background: #f56c0b;
                border-radius: 3px;
            }

            .el-tabs__item {
                padding: 0 23px;
                height: 64px;
                line-height: 64px;
                font-size: 16px;
                font-weight: 400;
            }

            .el-tabs__item:hover {
                color: #f56c0b;
            }

            .el-tabs__item.is-active {
                color: #f56c0b;
            }
        }
    }

    & .el-tabs__nav-wrap::after {
        background-color: #eee;
        height: 1px;
    }

    .noBottom {
        .el-tabs__active-bar {
            background: none !important;
        }
    }

    .el-button--warning {
        background: #fff;
        color: #f56c0b;
        border-color: #f56c0b;

        &:hover {
            background: #f56c0b !important;
            color: #fff;
        }

        &:focus {
            background: #f56c0b !important;
            color: #fff;
        }
    }
}

.written {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
    position: relative;
}
</style>
